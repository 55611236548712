import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import MultiSelect from './MultiSelect';
import carrotLogo from '../assets/images/carrot-logo-orange.png';
// import carrotLogo from '../assets/images/carrot-logo-orange-xmas.png'; // xmas
import * as Helpers from '../helpers';

class Banner extends Component {
  render() {
    const {
      userData, currentPage, out, dropdown, switchPortfolio, app, applications, appSelect,
    } = this.props;

    return (
      <div id="dashboard-header-parent" className="mobile-display-none">
        <div id="dashboard-header">
          <Link style={{ textDecoration: 'none' }} to={currentPage === 'dashboard' ? '/' : '/dashboard'}><img src={carrotLogo} alt="carrot logo" className="dashboard-header-logo" /></Link>
          <div id="dashboard-header-items">
            {appSelect && (
              <>
                <MultiSelect key="banner-multiselect" switchPortfolio={switchPortfolio} app={app} applications={applications} size="big" />
                <div className="vertical-divider" />
              </>
            )}

            {/* <div><Link style={{ textDecoration: 'none' }} to="/profile">My Profile</Link></div> */}
            {/* <div><Link style={{ textDecoration: 'none' }} to="/faqs">FAQs</Link></div>
            <div><Link style={{ textDecoration: 'none' }} to="/forgot">Password</Link></div> */}
            {/* <div id="log-out" onClick={this.out}>Log out</div> */}
            {dropdown && (
              <div>
                <DropdownButton id="dropdown-basic-button" title={userData && userData.firstname && Helpers.capitalizeFirstLetter3(`Hi, ${userData.firstname}`)} variant="secondary">
                  {currentPage !== 'profile' && (<Link style={{ textDecoration: 'none' }} to="/profile"><Dropdown.Item as="button">My Profile</Dropdown.Item></Link>)}
                  {currentPage !== 'dashboard' && (<Link style={{ textDecoration: 'none' }} to="/dashboard"><Dropdown.Item as="button">Dashboard</Dropdown.Item></Link>)}
                  <Link style={{ textDecoration: 'none' }} to="/forgot"><Dropdown.Item as="button">Reset Password</Dropdown.Item></Link>
                  <Dropdown.Item href="/faqs" target="_blank" rel="noopener noreferrer">FAQs</Dropdown.Item>
                  <Dropdown.Item onClick={out}>Log out</Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
