import React from 'react';

import greyLogo from '../assets/images/carrot-logo-grey.svg';
// import greyLogo from '../assets/images/carrot-logo-orange-xmas.png'; // xmas

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="footerSection" id="footerSection">
      <div className="footerFlex ">
        <div className="carrotLogoFooter ">
          <a href="/">
            <img src={greyLogo} alt="logo" width="90" />
          </a>
        </div>

        <div className="footerLinkDiv">
          <div className="footerLink">
            <div className="footList">
              <ul>
                <li><a href="/home/business">For Business</a></li>

                <li><a href="/signup">For Individuals</a></li>
              </ul>
            </div>
          </div>

          <div className="footerLink">
            <div className="footList">
              <ul>
                <li><a href="/signup">Sign up</a></li>
                <li><a href="/signin">Log in</a></li>
              </ul>
            </div>
          </div>

          <div className="footerLink">
            <div className="footList">
              <ul>
                <li><a href="/user-agreement">User Agreement</a></li>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                {/* <li><a href="#footerSection">Legal</a></li> */}
              </ul>
            </div>
          </div>

          <div className="footerLink">
            <div className="footList">
              <ul>
                <li><a href="https://www.instagram.com/getcarrotcredit/">Instagram</a></li>
                <li><a href="https://www.twitter.com/getcarrotcredit/">Twitter</a></li>
                {/* <li><a href="#footerSection">Facebook</a></li> */}
              </ul>
            </div>
          </div>

        </div>

      </div>

      <div className="footerText">
        <hr />
        By using this website, you accept our
        {' '}
        <a href="/user-agreement">User Agreement</a>
        {' '}
        and
        {' '}
        <a href="/privacy-policy">Privacy Policy</a>
        .
        <br />
        ©
        {' '}
        {currentYear}
        , Carrot Inc. All Rights Reserved.
      </div>
    </section>
  );
};
export default Footer;
