import React from 'react';
import {
  Navbar, Footer, Section1, Review, Button, GetStarted
} from '../../components';

class Individual extends React.Component {
  heading = (
    <>
      <div>
        Don’t sell your assets Borrow against them
      </div>
    </>
  );

  CTA = (
    <div className="section-1">
      <Button cta>
        Start using Carrot today
      </Button>
    </div>
  );

  componentDidMount = () => {
    if (window.groove.widget) window.groove.widget.destroy();

    return () => {
      if (window.groove.widget) window.groove.widget.init('697d31d3-878e-400c-bd9d-96926e6917f8', {});
    };
  };

  render() {
    const { contact } = this.props;
    return (
      <div>
        <Navbar />
        <Section1
          background="individual"
          heading={this.heading}
          subheading="Leverage your assets to access a line of credit. Giving you the ability to spend on anything without having to sell your assets."
          CTA={this.CTA}
        />

        <Review />

        <GetStarted contact={contact} />
        <Footer />
      </div>
    );
  }
}

export default Individual;
