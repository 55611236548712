import * as Helpers from '../../helpers';

import { LOADING, ERROR } from './user';

export const GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS';
export const GET_USER_APPLICATIONS = 'GET_USER_APPLICATIONS';

export const getAllApplications = (success, error) => Helpers.api(
  '/application',
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: GET_ALL_APPLICATIONS }
);

export const getUserApplications = (user_id, success, error) => Helpers.api(
  `/application${user_id ? `?user_id=${user_id}` : ''}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: GET_USER_APPLICATIONS }
);
