import {
  PORTFOLIO_GET
} from '../actions';

const initialState = {
  all: [],
};

const modifyArray = (oldArray, newArray) => {
  const oldArrayCopy = [...oldArray];
  newArray.forEach(newObj => {
    const index = oldArrayCopy.findIndex(
      oldObj => oldObj.application.application_id === newObj.application.application_id
    );
    if (index > -1) {
      oldArrayCopy[index] = newObj;
    } else {
      oldArrayCopy.push(newObj);
    }
  });
  return oldArrayCopy;
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
  case PORTFOLIO_GET:
    return {
      ...state,
      all: modifyArray(initialState.all, payload.data.all),
    };

  default:
    return state;
  }
}
