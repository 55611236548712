import {
  GET_USER_APPLICATIONS,
  GET_ALL_APPLICATIONS
} from '../actions';

const initialState = {
  all: [],
  user: [],
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
  case GET_ALL_APPLICATIONS:
    return {
      ...state,
      all: payload.data.applications,
    };
  case GET_USER_APPLICATIONS:
    return {
      ...state,
      user: payload.data.applications,
    };

  default:
    return state;
  }
}
