import React from 'react';
import { Link } from 'react-router-dom';

import {
  Navbar, Button, Section1, Footer, AnimatedSection, GetStarted
} from '../../components';
import Faqs from './Faqs';

import individualImage from '../../assets/images/individual.png';
import businessImage from '../../assets/images/business.png';
import atmImage from '../../assets/images/atm-illustration.png';
import phoneImage from '../../assets/images/phone-illustration.png';
import checkImage from '../../assets/images/check.png';
import paystackLogo from '../../assets/images/logo-paystack.png';
import magicfundLogo from '../../assets/images/logo-magicfund.png';
import bambooLogo from '../../assets/images/logo-bamboo.png';
import partnersLogo from '../../assets/images/partners.png';
import aboutImage from '../../assets/images/about.png';

import './index.css';

const Home = ({ contact, user: { loading } }) => {
  const CTA = (
    <div className="section-1">
      <Link style={{ textDecoration: 'none' }} to="/signup">
        <Button cta>
          Start using Carrot
        </Button>
      </Link>
    </div>
  );

  const heading = (
    <>
      <div>
        Credit without
      </div>
      <div>
        the
        {' '}
        <span>hassle.</span>
      </div>
    </>
  );

  return (
    <div>
      <Navbar />
      <Section1
        background="landing"
        heading={heading}
        subheading="Carrot helps businesses and their customers access a line of credit collateralized by their assets (Stocks, Cash & Crypto)"
        CTA={CTA}
      />
      {/* ref={myRef} className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} */}
      <section id="section-2">
        <AnimatedSection className="businessItem">
          <div className="businessItem">
            <div className="heading-text">
              Business
            </div>
            <div className="content-text">
              Stop your customers cyclical withdrawals with a credit line that empowers them.
            </div>
            <div className="section-2-button">
              <Link style={{ textDecoration: 'none' }} to="/home/business">
                <Button cta>Learn More</Button>
              </Link>
            </div>
            <div className="section-2-image">
              <img src={businessImage} alt="business" />
            </div>
          </div>
        </AnimatedSection>

        <AnimatedSection className="individualItem">
          <div>
            <div className="heading-text">
              Individual
            </div>
            <div className="content-text">
              Use your assets as collateral instead of selling them.
            </div>
            <div className="section-2-button">
              <Link style={{ textDecoration: 'none' }} to="/signup">
                <Button cta>Start using Carrot</Button>
              </Link>
            </div>
            <div className="section-2-image">
              <img src={individualImage} alt="individual" />
            </div>
          </div>
        </AnimatedSection>
      </section>

      <AnimatedSection>
        <section id="section-3">
          <div className="section-3-image">
            <img src={aboutImage} alt="about" />
          </div>

          <div className="section-3-text">
            <div className="green-text">
              What is Carrot
            </div>
            <div className="heading-text">
              Our application process takes less time than making a cup of coffee.
            </div>
            <div className="content-text">
              Carrot provides a quick line of credit so you never have to liquidate assets
            </div>

            <hr className="section-3-hr" />

            <div className="section-3-list">
              <ul className="aboutIcon">
                <li className="list-text">
                  <span><div className="checkCircle"><img className="bi-check" src={checkImage} alt="check" /></div></span>
                  Access Carrot from partner app
                </li>
                <li className="list-text">
                  <span><div className="checkCircle"><img className="bi-check" src={checkImage} alt="check" /></div></span>
                  Fill out application form
                </li>
                <li className="list-text">
                  <span><div className="checkCircle"><img className="bi-check" src={checkImage} alt="check" /></div></span>
                  Credit disbursed in less than 15 mins!
                </li>
              </ul>
            </div>
          </div>
        </section>
      </AnimatedSection>

      <section id="section-4">
        <AnimatedSection className="assetsItem" variant="fade-in-sectionLeft">
          <div>
            <div className="heading-text">
              Use your existing digital assets
            </div>
            <div className="content-text">
              With us your customers no longer have to sell their assets for cash - they get a credit line backed by their assets instead.
            </div>
            <div className="section-4-image">
              <img src={atmImage} alt="digital assets" />
            </div>
          </div>
        </AnimatedSection>

        <AnimatedSection className="reliableItem" variant="fade-in-sectionRight">
          <div>
            <div className="heading-text">
              Fast & Reliable
            </div>
            <div className="content-text">
              Quick business integration and credit application processes for your customers.
              {' '}
              {' '}
            </div>
            <div className="section-4-image reliable">
              <img src={phoneImage} alt="fast and reliable" />
              <div />
            </div>
          </div>
        </AnimatedSection>
      </section>

      <AnimatedSection>
        <section id="section-5">
          <div className="partnersText">
            <div className="heading-text">
              Our partners
            </div>
            <div className="subheading-text">
              Over 5k users trust Carrot
            </div>
            <div className="content-text">
              For life’s special moments and its emergencies, we have created a product that covers every need.
              <br />
              We’re also currently discussing with several financial institutions to bring Carrot to their customers. Join us!
            </div>
            <div className="partnersLogos">
              <div className="logo">
                <img src={paystackLogo} alt="logo" />
              </div>
              <div className="logo">
                <img src={magicfundLogo} alt="logo" />
              </div>
              <div className="logo">
                <img src={bambooLogo} alt="logo" />
              </div>
            </div>
          </div>
          <div className="partnersImage">
            <img src={partnersLogo} alt="partners" />
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section id="FAQSection">
          <div className="heading-text">
            Frequently Asked Questions
          </div>
          <Faqs />
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <GetStarted contact={contact} loading={loading} />
      </AnimatedSection>

      <AnimatedSection>
        <Footer />
      </AnimatedSection>
    </div>
  );
};

export default Home;
