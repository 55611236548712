import * as Helpers from '../../helpers';

import { ERROR, LOADING } from './user';
// import store from '..';

export const LOAN_CREATE = 'LOAN_CREATE';
export const LOAN_GET = 'LOAN_GET';
export const LOAN_GET_SINGLE = 'LOAN_GET_SINGLE';
export const LOAN_SET_OFFSET = 'LOAN_SET_OFFSET';
export const LOAN_CANCEL = 'LOAN_CANCEL';
export const LOAN_CLEAR = 'LOAN_CLEAR';

export const requestLoan = (applicationID, amount, loanTenure, success, error) => Helpers.api(
  '/loan',
  'POST',
  { amount, application_id: applicationID, tenure: loanTenure },
  { error, success },
  { error: ERROR, loading: LOADING, responder: LOAN_CREATE }
);

export const setLoanOffset = (limit, offset) => dispatch => dispatch({ payload: { limit, offset }, type: LOAN_SET_OFFSET });

export const getLoans = (limit, offset, application_id, success) => Helpers.api(
  `/loan?limit=${limit || 10}&offset=${offset || 0}${application_id ? `&application_id=${application_id}` : ''}`,
  'GET',
  null,
  { success },
  {
    error: ERROR, loading: LOADING, responder: LOAN_GET,
  }
);

export const clearLoans = () => dispatch => {
  dispatch({ type: LOAN_CLEAR });
};

export const getSingleLoan = (loan_id, success, error) => Helpers.api(
  `/loan/${loan_id}`,
  'GET',
  null,
  { error, success },
  { error: ERROR, loading: LOADING, responder: LOAN_GET_SINGLE }
);

export const cancelLoan = (loan_id, success, error) => Helpers.api(
  '/loan',
  'PUT',
  { loan_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: LOAN_CANCEL }
);
